import React from 'react'
import SbEditable from 'storyblok-react'
import { render } from 'storyblok-rich-text-react-renderer'

import ImageBlok from '../ImageBlok'
import TextBlok from '../TextBlok'
import './styles.scss'

const OpenOfficeItem = ({ blok }) => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const date = blok.date ? new Date(blok.date.split(' ')[0]) : new Date()

  return (
    <SbEditable content={blok} key={blok._uid}>
      <div className="journal main__padded">
        <h1 className="journal__title">{blok.title}</h1>
        <p className="journal__author">{blok.author}</p>
        <p className="journal__date">
          {monthNames[date.getMonth()]} {date.getDate()}, {date.getFullYear()}
        </p>
        <div className="journal__body">
          {render(blok.body, {
            blokResolvers: {
              ['ImageBlok']: (props) => <ImageBlok blok={props} />,
              ['TextBlok']: (props) => <TextBlok blok={props} />,
            },
          })}
        </div>
      </div>
    </SbEditable>
  )
}

export default OpenOfficeItem
