import React from 'react'
import SbEditable from 'storyblok-react'
import Journal from './Journal'
import JournalList from './Journal/JournalList'
import Project from './Project'
import ProjectList from './Project/ProjectList'
import OpenOfficeItem from './OpenOffice'
import OpenOfficeList from './OpenOffice/OpenOfficeList'
import ImageBlok from './ImageBlok'
import TextBlok from './TextBlok'

const Components = {
  Journal,
  JournalList,
  Project,
  ProjectList,
  OpenOfficeItem,
  OpenOfficeList,
  ImageBlok,
  TextBlok,
}

const DynamicComponent = ({ blok }) => {
  if (typeof Components[blok.component] !== 'undefined') {
    const Component = Components[blok.component]
    return (
      <SbEditable content={blok}>
        <Component blok={blok} />
      </SbEditable>
    )
  }
  return (
    <p>
      The component <strong>{blok.component}</strong> doesn't exist yet.
    </p>
  )
}

export default DynamicComponent
