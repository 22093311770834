import React, { useState } from 'react'
import SbEditable from 'storyblok-react'
import { useStaticQuery, graphql } from 'gatsby'
import { render } from 'storyblok-rich-text-react-renderer'
import { Document, Page, pdfjs } from 'react-pdf'
import Modal from 'react-modal'

import './styles.scss'
Modal.setAppElement('#___gatsby')
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const OpenOfficeList = ({ blok }) => {
  const [modalIsOpen, setIsOpen] = useState(false)
  const [pdfPath, setPdfPath] = useState('')
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
  // const [sortOrder, setSortOrder] = useState('az')

  function goPagePrev() {
    setPageNumber(pageNumber - 1)
  }
  function goPageNext() {
    setPageNumber(pageNumber + 1)
  }

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
    setPdfPath('')
    setNumPages(null)
    setPageNumber(1)
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  function openPdf(pdfPath) {
    const newPath = pdfPath.replace(
      'a.storyblok',
      's3.amazonaws.com/a.storyblok',
    )
    setPdfPath(newPath)
    openModal()
  }

  const modalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      color: 'black',
      maxHeight: '90vh',
      maxWidth: '90vw',
    },
  }

  const getUnderlineStyle = (condition) =>
    condition ? { textDecoration: 'underline' } : {}

  let filteredItems = []
  const isResolved = typeof blok.items[0] !== 'string'

  const data = useStaticQuery(graphql`
    {
      items: allStoryblokEntry(
        filter: { field_component: { eq: "OpenOfficeItem" } }
      ) {
        edges {
          node {
            id
            uuid
            name
            slug
            full_slug
            content
            created_at
          }
        }
      }
    }
  `)

  if (!isResolved) {
    filteredItems = data.items.edges.filter(
      (p) => blok.items.indexOf(p.node.uuid) > -1,
    )

    filteredItems = filteredItems.map((edge) => {
      const content = edge.node.content
      const newContent =
        typeof content === 'string' ? JSON.parse(content) : content

      edge.node.content = newContent

      return edge.node
    })
  }

  const items = isResolved ? blok.items : filteredItems
  const yearsFound = items
    .reduce((acc, item) => {
      const year = new Date(item.content.date.split(' ')[0]).getFullYear()
      if (!acc.includes(year)) {
        acc.push(year)
      }
      return acc
    }, [])
    .sort((a, b) => b - a)
  const itemsByYear = items.filter(
    (item) =>
      new Date(item.content.date.split(' ')[0]).getFullYear() === selectedYear,
  )
  const sortedItems = [...itemsByYear].sort((a, b) => {
    if ('desc') {
      return (
        new Date(b.content.date.split(' ')[0]) -
        new Date(a.content.date.split(' ')[0])
      )
    } else if ('asc') {
      return (
        new Date(a.content.date.split(' ')[0]) -
        new Date(b.content.date.split(' ')[0])
      )
    } else {
      return b.content.artist.localeCompare(a.content.artist)
    }
  })
  const artists = sortedItems.reduce((acc, item) => {
    const { artist } = item.content

    if (acc[artist]) {
      acc[artist].push(item)
    } else {
      acc[artist] = [item]
    }

    return acc
  }, {})

  return (
    <SbEditable content={blok} key={blok._uid}>
      <div className="open-office-list main__padded">
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={modalStyles}
          contentLabel="Example Modal"
        >
          <Document file={pdfPath} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} />
          </Document>
          {numPages && (
            <div className="pdf__controls">
              <p>
                Page {pageNumber} of {numPages}
              </p>
              {pageNumber > 1 && <div onClick={goPagePrev}>&lsaquo;</div>}
              {pageNumber < numPages && (
                <div onClick={goPageNext}>&rsaquo;</div>
              )}
              <a href={pdfPath} download>
                Download
              </a>
            </div>
          )}
        </Modal>

        <div className="open-office-list__text">{render(blok.text)}</div>
        <div className="open-office-list__content">
          <div className="open-office-list__years">
            {yearsFound.map((year) => (
              <div
                onClick={() => setSelectedYear(year)}
                style={getUnderlineStyle(selectedYear === year)}
                key={year}
              >
                {year}
              </div>
            ))}
          </div>

          {/* <div className="open-office-list__sorting">
            <div
              onClick={() => setSortOrder('az')}
              style={getUnderlineStyle(sortOrder === 'az')}
            >
              A-Z
            </div>
            <div
              onClick={() => setSortOrder('desc')}
              style={getUnderlineStyle(sortOrder === 'desc')}
            >
              Newest
            </div>
            <div
              onClick={() => setSortOrder('asc')}
              style={getUnderlineStyle(sortOrder === 'asc')}
            >
              Oldest
            </div>
          </div> */}

          <div className="open-office-list__items">
            {Object.keys(artists)
              .sort()
              .map((artist) => (
                <div key={artist} className="open-office-list__section">
                  <h2 className="open-office-list__section__title">{artist}</h2>
                  <div className="open-office-list__section__items-container">
                    {artists[artist].map(({ content: item, uuid }) => {
                      const pdfParts = item.pdf.filename.split('/')

                      return (
                        <div
                          className="open-office-list__section__item"
                          key={uuid}
                          onClick={() => openPdf(item.pdf.filename)}
                        >
                          <div className="open-office-list__section__item__image">
                            <img
                              src={item.image.filename}
                              alt={item.image.alt}
                            />
                          </div>
                          <p className="open-office-list__section__item__title">
                            {item.title}
                          </p>
                          <p className="open-office-list__section__item__description">
                            {item.description}
                          </p>
                          <p className="open-office-list__section__item__description">
                            {item.artist}
                          </p>
                        </div>
                      )
                    })}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </SbEditable>
  )
}

export default OpenOfficeList
