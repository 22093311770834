import React from 'react'
import SbEditable from 'storyblok-react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { render } from 'storyblok-rich-text-react-renderer'

import './styles.scss'

const JournalList = ({ blok }) => {
  let filteredJournals = []
  const isResolved = typeof blok.journals[0] !== 'string'

  const data = useStaticQuery(graphql`
    {
      journals: allStoryblokEntry(
        filter: { field_component: { eq: "Journal" } }
      ) {
        edges {
          node {
            id
            uuid
            name
            slug
            full_slug
            content
            created_at
          }
        }
      }
    }
  `)

  if (!isResolved) {
    filteredJournals = data.journals.edges.filter(
      (p) => blok.journals.indexOf(p.node.uuid) > -1,
    )

    filteredJournals = filteredJournals.map((edge) => {
      const content = edge.node.content
      const newContent =
        typeof content === 'string' ? JSON.parse(content) : content

      edge.node.content = newContent

      return edge.node
    })
  }

  const journals = isResolved ? blok.journals : filteredJournals
  const sortedJournals = [...journals].sort(
    (a, b) =>
      new Date(b.content.date.split(' ')[0]) -
      new Date(a.content.date.split(' ')[0]),
  )

  const yearSections = sortedJournals.reduce((acc, journal) => {
    const journalYear = new Date(
      journal.content.date.split(' ')[0],
    ).getFullYear()

    if (acc[journalYear]) {
      acc[journalYear].push(journal)
    } else {
      acc[journalYear] = [journal]
    }

    return acc
  }, {})

  return (
    <SbEditable content={blok} key={blok._uid}>
      <div className="journal-list main__padded">
        <div className="journal-list__text">{render(blok.text)}</div>
        <div className="journal-list__content">
          {Object.keys(yearSections)
            .sort((a, b) => b - a)
            .map((year) => (
              <div key={year} className="journal-list__section">
                <h2 className="journal-list__section__title">{year}</h2>
                <div className="journal-list__section__items-container">
                  {yearSections[year].map(
                    ({ full_slug, content: journal, uuid }) => (
                      <Link
                        className="journal-list__section__item"
                        to={`/${full_slug}`}
                        key={uuid}
                      >
                        <div className="journal-list__section__item__image">
                          <img
                            src={journal.preview_image.filename}
                            alt={journal.preview_image.alt}
                          />
                        </div>
                        <p className="journal-list__section__item__title">
                          {journal.title}
                        </p>
                        <p className="journal-list__section__item__author">
                          {journal.author}
                        </p>
                      </Link>
                    ),
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </SbEditable>
  )
}

export default JournalList
