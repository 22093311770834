import React from 'react'
import SbEditable from 'storyblok-react'
import { render } from 'storyblok-rich-text-react-renderer'

import './styles.scss'

const ImageBlok = ({ blok }) => {
  const { image_alignment: alignment } = blok
  let flexDirection = ''
  let imageFlexBasis = `${blok.image_width / 2}%`
  let textFlexBasis = `${100 - blok.image_width / 2}%`

  if (alignment === 'full_width') {
    flexDirection = 'column'
  } else if (alignment === 'left') {
    flexDirection = 'row'
  } else {
    flexDirection = 'row-reverse'
  }

  const blokStyle = {
    flexDirection,
  }
  const imageStyle = {
    flexBasis: imageFlexBasis,
    width: `${blok.image_width}%`,
  }
  const textStyle = {
    flexBasis: textFlexBasis,
  }

  return (
    <SbEditable content={blok}>
      <div className="image-blok" style={blokStyle}>
        <div
          className="image-blok__section image-blok__section--image"
          style={imageStyle}
        >
          <img
            className="image-blok__image"
            src={blok.image.filename}
            alt={blok.image.alt}
          />
          <p className="image-blok__caption">{blok.caption}</p>
          <p className="image-blok__credit">{blok.credit}</p>
        </div>
        <div
          className="image-blok__section image-blok__section--text"
          style={textStyle}
        >
          {render(blok.text)}
        </div>
      </div>
    </SbEditable>
  )
}

export default ImageBlok
