import React from 'react'
import SbEditable from 'storyblok-react'
import { render, MARK_LINK } from 'storyblok-rich-text-react-renderer'

import { SettingsContext } from '../Layout'
import './styles.scss'

const TextBlok = ({ blok }) => {
  const settings = React.useContext(SettingsContext)
  const textBlokStyle = {
    backgroundColor: blok.background_color,
    color: blok.text_color,
  }
  const flexBasis = blok.full_width ? {} : { flexBasis: '50%' }
  const linkColorStyle = { color: `${settings.link_color}` }

  const renderBlok = (blok) =>
    render(blok, {
      markResolvers: {
        [MARK_LINK]: (children, { href, target }) =>
          target === '_self' ? (
            <Link style={linkColorStyle} to={href}>
              {children}
            </Link>
          ) : (
            <a style={linkColorStyle} href={href} target={target}>
              {children}
            </a>
          ),
      },
    })

  return (
    <SbEditable content={blok}>
      <div className="text-blok" style={textBlokStyle}>
        <div
          className="text-blok__section text-blok__section--left"
          style={flexBasis}
        >
          {renderBlok(blok.left)}
        </div>
        {!blok.full_width && (
          <div
            className="text-blok__section text-blok__section--right"
            style={flexBasis}
          >
            {renderBlok(blok.right)}
          </div>
        )}
      </div>
    </SbEditable>
  )
}

export default TextBlok
