import React from 'react'
import SbEditable from 'storyblok-react'
import { render } from 'storyblok-rich-text-react-renderer'

import ProjectAsset from './ProjectAsset'
import TextBlok from '../TextBlok'
import './styles.scss'

const Project = ({ blok }) => {
  const descriptionStyle = {
    textAlign: blok.description_alignment === 'left' ? 'left' : 'justify',
  }
  const authors = blok.authors?.split(',').map((author) => author.trim()) || []
  const bgImageStyle = {
    backgroundImage: `url("${blok.hero_background_image?.filename}")`,
  }
  const bgColor = blok.background_color || 'linear-gradient(black, #303030)'

  return (
    <SbEditable content={blok} key={blok._uid}>
      <div className="project" style={{ background: bgColor }}>
        <div className="project__section">
          <div className="project__section--left">
            <div
              className="project__section--left__image"
              style={bgImageStyle}
            ></div>
            <div style={{ zIndex: 1 }}>
              {render(blok.title)}
              {authors.map((author) => (
                <p key={author} className="project__author">
                  {author}
                </p>
              ))}
              <p className="project__year">{blok.year}</p>
            </div>
          </div>

          <div className="project__section--right">
            <div className="project__description" style={descriptionStyle}>
              {render(blok.description)}
            </div>
          </div>
        </div>

        {render(blok.body, {
          blokResolvers: {
            ProjectAsset: (props) => <ProjectAsset blok={props} />,
            TextBlok: (props) => <TextBlok blok={props} />,
          },
        })}
      </div>
    </SbEditable>
  )
}

export default Project
