import React from 'react'
import SbEditable from 'storyblok-react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { render } from 'storyblok-rich-text-react-renderer'

import './styles.scss'

const ProjectList = ({ blok }) => {
  let filteredProjects = []
  const isResolved = typeof blok.projects[0] !== 'string'

  const data = useStaticQuery(graphql`
    {
      projects: allStoryblokEntry(
        filter: { field_component: { eq: "Project" } }
      ) {
        edges {
          node {
            id
            uuid
            name
            slug
            full_slug
            content
            created_at
          }
        }
      }
    }
  `)

  if (!isResolved) {
    filteredProjects = data.projects.edges.filter(
      (p) => blok.projects.indexOf(p.node.uuid) > -1,
    )

    filteredProjects = filteredProjects.map((edge) => {
      const content = edge.node.content
      const newContent =
        typeof content === 'string' ? JSON.parse(content) : content

      edge.node.content = newContent

      return edge.node
    })

    filteredProjects = filteredProjects.sort((a, b) => {
      return blok.projects.indexOf(a.uuid) - blok.projects.indexOf(b.uuid)
    })
  }

  const projects = isResolved ? blok.projects : filteredProjects

  return (
    <SbEditable content={blok} key={blok._uid}>
      <div className="project-list main__padded">
        <div className="project-list__text">{render(blok.text)}</div>
        <ul className="project-list__items">
          {projects.map(({ full_slug, content: project, uuid }, index) => (
            <li key={uuid} className="project-list__item">
              <Link
                className="project-list__item__link"
                to={`/${full_slug}/preview`}
              >
                PROJECT {index + 1}: {project.display_title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </SbEditable>
  )
}

export default ProjectList
